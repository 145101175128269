import React, { useState } from "react";
import Layout from "../../components/Common/Layout";
import About from "../../components/Website/About";
import Disciplines from "../../components/Website/Disciplines";
import OurServices from "../../components/Website/OurServices";
import WorkWithUs from "../../components/Website/WorkWithUs";

const Solutions = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="pt"
      location="services"
      title="Brocode Studio || Página de serviços"
      description="Esta apresentação de alguns dos serviços brocode pode ajudá-lo com"
    >
      <About selectedLanguage="pt" />
      <Disciplines selectedLanguage="pt" />
      <OurServices setModalVisible={setModalVisible} selectedLanguage="pt" />
      <WorkWithUs setModalVisible={setModalVisible} selectedLanguage="pt" />
    </Layout>
  );
};

export default Solutions;
